import React, { useState } from "react";
import Layout from "../../components/layout";
import { Container, H1, CheckBox, PrimaryButton } from "../../styles/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "gatsby";
import ShiftBy from "../../lib/shiftBy";

const OktatasTanacsadas: React.FC = () => {
  const [status, setStatus] = useState<string>("");
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { name: "", email: "", message: "", checkbox: false },
  });
  const fields = watch();

  const onSubmit: SubmitHandler<{
    name: string;
    email: string;
    message: string;
    checkbox: boolean;
  }> = () => {
    const contactFormData = new FormData();
    contactFormData.append("name", fields["name"]);
    contactFormData.append("email", fields["email"]);
    contactFormData.append("message", fields["message"]);
    contactFormData.append("checkbox", String(fields["checkbox"]));

    axios({
      method: "post",
      url: `${process.env.GATSBY_SITE_URL}/api/send-contact-message.php`,
      data: contactFormData,
      // headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data === "success") {
          reset({ name: "", email: "", message: "" });
          setStatus("Az üzenetedet megkaptuk!");
        }
      })
      .catch((error) => {
        console.log("Error sending contact form, ", error);
        setStatus(
          `Hiba az üzenet küldése során.
           Kérjük próbáld újra, vagy írj az info@compassmarketing.hu címre.`
        );
      });
  };

  // React.useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Oktatás és Tanácsadás"
      description="Oktatás és Tanácsadás"
    >
      <Wrapper>
        <div>
          <H1>Oktatás, Tanácsadás</H1>
          <br />
          <h4>
            Szeretnél fejlődni a marketing és kereskedelem területén? Vagy
            fejlesztenéd munkavállalóidat? Ha igen, akkor:
          </h4>
          <StepTitle>
            <div>
              <h2>1</h2>
            </div>
            <p>
              Egyeztessünk időpontot, ahol személyre szabottan felmérjük neked,
              milyen területeken érdemes fejlesztened.
            </p>
          </StepTitle>
          <StepTitle>
            <div>
              <h2>2</h2>
            </div>
            <p>
              Amennyiben már tudod, hogy mit szeretnél, vedd fel velünk a
              kapcsolatot, hogy átbeszéljük a lehetőségeket.
            </p>
          </StepTitle>
          {/* {!single && ( */}
          <div
            css={css`
              width: 80%;
              @media (max-width: 1065px) {
                width: 100%;
              }
            `}
          >
            <StaticImage
              src="../../images/folyamat1.jpg"
              alt="Oktatás"
              objectFit="contain"
              imgStyle={{ borderRadius: "20px" }}
            />
          </div>
          {/* )} */}
        </div>
        <Contact>
          <h3>Vedd fel velünk a kapcsolatot!</h3>
          <p>
            Az űrlap kitöltésével jelezd oktatással vagy tanácsadással
            kapcsolatot igényedet.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <label>Név *</label>
            <input
              type="text"
              // placeholder="Joe Smith"
              {...register("name", { required: true, maxLength: 100 })}
            />
            <label>E-mail cím *</label>
            <input
              type="email"
              // placeholder="joe.smith@gmail.com"
              {...register("email", { required: true })}
            />
            <label>Üzenet *</label>
            <textarea
              // placeholder="Write your message here..."
              {...register("message", { required: true })}
            />

            <div className="checkbox-wrapper">
              <div>
                <CheckBox {...register("checkbox", { required: true })} />
              </div>
              <ShiftBy y={2}>
                <p>
                  Az űrlap kitöltésével és elküldésével elfogadom az{" "}
                  <Link to="/marketing/adatkezelesi-tajekoztato">
                    Adatkezelési tájékoztatóban
                  </Link>{" "}
                  foglaltakat.
                </p>
              </ShiftBy>
            </div>

            <div className="button-wrapper">
              <PrimaryButton aria-label={"Küldés"} type="submit">
                KÜLDÉS
              </PrimaryButton>
            </div>
          </form>
          <StatusWrapper>
            {status && (
              <p style={{ textAlign: "center", border: 0 }}>{status}</p>
            )}
            {errors?.name && (
              <p style={{ textAlign: "center", border: 0 }}>
                Név megadása kötelező!
              </p>
            )}
            {errors?.email && (
              <p style={{ textAlign: "center", border: 0 }}>
                Email megadása kötelező
              </p>
            )}
            {errors?.message && (
              <p style={{ textAlign: "center", border: 0 }}>
                Üzenet megadása kötelező!
              </p>
            )}
            {errors?.checkbox && (
              <p style={{ textAlign: "center", border: 0 }}>
                Adatvédelmi nyilatkozat elfogadása kötelező!
              </p>
            )}
          </StatusWrapper>
        </Contact>
      </Wrapper>
    </Layout>
  );
};

export default OktatasTanacsadas;

const Wrapper = styled(Container)`
  padding-top: 160px;
  padding-bottom: 96px;
  background: white;

  display: grid;
  column-gap: 32px;
  grid-template-columns: 1fr 1fr;

  h4 {
    font-weight: 500;
  }

  @media (max-width: 1065px) {
    grid-template-columns: 1fr;
  }
`;

const StepTitle = styled.div`
  display: grid;
  grid-template-columns: 88px 1fr;
  margin: 32px 0;

  div {
    width: 40px;
    height: 40px;
    background: var(--color-light-blue);
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
  }
  h2 {
    margin: 0;
    color: var(--color-primary);
  }
`;

const Contact = styled.div`
  width: 100%;
  /* do not stretch to bottom of grid */
  margin-bottom: auto;
  margin-top: 128px;
  background: linear-gradient(145deg, #f4f4f4 0%, #f9f9f9 100%);
  border-radius: 20px;
  box-shadow: var(--shadow);
  /* box-shadow: var(--shadow-elevation-medium); */

  padding: 32px;

  h3 {
    text-align: center;
    margin-bottom: 32px;
  }

  form {
    label {
      margin-top: 8px;
      display: block;
    }
    input,
    textarea {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #ccc;

      color: var(--color-middle-gray);
      padding-left: 0.5rem;
      padding-top: 4px;
      &:focus {
        border: 1px solid var(--color-primary);
        outline: 1px solid var(--color-primary);
      }
    }
    textarea {
      resize: none;
      height: 5rem;
    }

    .checkbox-wrapper {
      display: grid;
      grid-template-columns: 36px 1fr;

      .MuiSvgIcon-root {
        font-size: 1.2rem;
      }
      p {
        font-size: 0.9375rem;
        line-height: 1.2;
      }
      a {
        font-size: inherit;
        text-decoration: underline;
        color: inherit;
      }
    }

    .button-wrapper {
      margin-top: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 80px;
    }
  }

  @media (max-width: 1065px) {
    padding: 16px;
    margin-top: 64px;
    form textarea {
      height: 10rem;
    }
  }
`;

const StatusWrapper = styled.div`
  p {
    margin: 0;
  }
  p:first-of-type {
    margin-top: 10px;
  }
`;
